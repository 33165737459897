import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';
import { useWeb3Modal } from '@web3modal/ethers5/react';

import { useWeb3Context } from 'src/context/web3';
import useAuthReducer from 'src/hooks/reducers/useAuthReducer';
import { selectAuth } from 'src/redux/store';

import Button from '../Button';
import ImageC from '../Image';

import classes from './index.module.less';

const SectionPremium = () => {
	const [isDisable, setIsDisable] = useState(false);

	const { isConnected, address } = useWeb3Context();
	const { open } = useWeb3Modal();
	const { user } = useAuthReducer();

	const authSlice = useSelector(selectAuth);
	const router = useRouter();

	const handleSubscribe = async () => {
		if (authSlice?.modalAuthOpen) {
			return false;
		}

		if (!address && !isConnected) {
			setIsDisable(true);
			return open();
		}

		return router.push('/subscription');
	};

	useEffect(() => {
		const conditional = isConnected && isDisable && user?.profile;
		if (conditional) {
			router.push('/subscription');
			setIsDisable(false);
		}
	}, [isConnected, user]);

	return (
		<div className={classes['section-premium-container']}>
			<div className="container d-flex flex-column align-items-center">
				{/* <div > */}
				<ImageC
					alt="image premium"
					className={classes.image}
					src="/images/premium.png"
					width={554}
				/>
				{/* </div> */}
				<h2>
					Enjoy More Benefits with
					<br />
					<span>CreoPlay Premium</span>
				</h2>
				<Button disabled={isDisable} className={classes['subscribe-btn']} onClick={handleSubscribe}>
					{isDisable ? 'Syncing..' : 'Subscribe'}
				</Button>
			</div>
		</div>
	);
};

export default SectionPremium;

import Slider from 'react-slick';
import Link from 'next/link';
import Grid from 'antd/lib/grid';
import classNames from 'classnames';

import { IBanner } from 'src/interface/Banners';

import ImageC from '../Image';
import Skeleton from '../Skeleton';

import classes from './index.module.less';

interface Props {
	className?: string;
	items: IBanner[];
	isLoading: boolean;
}

const defaultPadding = '25%';
const getPadding = (breakpoint: any) => {
	const isBreakpointReady = Object.keys(breakpoint).length !== 0;

	if (!isBreakpointReady) return defaultPadding;

	const { md, lg, xl } = breakpoint;

	let paddingSize;
	if (xl || lg) {
		paddingSize = defaultPadding;
	} else if (md) {
		paddingSize = '62px';
	} else {
		paddingSize = '0px';
	}

	return paddingSize;
};

const CardBanner = ({ item, isLoading }: { item: IBanner; isLoading: boolean }) => {
	if (isLoading) {
		return <Skeleton isLoading={isLoading} className={classes['card-banner']} />;
	}

	return (
		<Link href={item?.link} className="cursor-pointer">
			<div className={classNames(classes['card-banner'])}>
				<div className={classes['content-wrapper']}>
					<ImageC
						priority
						alt={`banner ${item?.title}`}
						className={classes.image}
						src={item?.image_url}
					/>
				</div>
			</div>
		</Link>
	);
};

const MainBanners = ({ className = '', items, isLoading }: Props) => {
	const breakpoint = Grid.useBreakpoint();
	const centerPadding = getPadding(breakpoint);

	if (!isLoading && (!items || items?.length === 0)) {
		return null;
	}

	const banners = items?.length === 1 ? [...items, ...items] : items;

	return (
		<div className={classNames(classes['banner-slider-container'], className)}>
			<Slider
				key={JSON.stringify(items ?? {})}
				className={classNames(classes.slider, 'center')}
				dots
				infinite
				autoplay={!isLoading}
				autoplaySpeed={5000}
				adaptiveHeight={true}
				centerMode={true}
				centerPadding={centerPadding}
				slidesToShow={1}
			>
				{(isLoading ? new Array(3).fill({ id: 'loading' }) : banners)?.map(
					(item: IBanner, index) => (
						<div key={item?.title + index} className={classes['slide-container']}>
							<CardBanner item={item} isLoading={isLoading} />
						</div>
					),
				)}
			</Slider>
		</div>
	);
};

export default MainBanners;
